<template>
  <el-container direction="vertical" style="height: 100%" class="container">
    <div v-if="showCode" style="width: 100%;">
      <!-- <h1 style="margin-bottom: 50px;">小程序码生成</h1> -->
      <div v-show="xianshia">
        <div ref="screenshotElement">
          <div class="zitia" style="padding-top:30px;max-width:750rpx;font-size:26px;text-align:center;">{{
            canshu.school_name }}
          </div>
          <div class="ma" style="margin-top: 20px;">
            <img v-if="src" :src="src" alt="小程序码" />
          </div>
        </div>
        <div style="margin-top: 100px;">
          <el-button style="width: 200px;" type="warning" @click="downLoad()">下载</el-button>
        </div>
        <!-- <hr style="width: 90%;margin-top: 30px;" /> -->
      </div>
    </div>
    <div v-if="!showCode" style="width: 100%;">
      <h2 style="margin-top: 30px;margin-bottom: 30px;font-size:20px;">「入园、入校」小程序码生成</h2>
      <el-form :model="canshu" :rules="rules" ref="canshu" class="demo-form-inline" label-width="100px" size="medium">
        <el-form-item label="学校名称" style="width: 90%;" prop="school_name">
          <el-input v-model="canshu.school_name" placeholder="请输入学校名称"></el-input>
        </el-form-item>
        <el-form-item style="width: 90%;margin-top: 100px;">
          <el-button type="primary" @click="onSubmit('canshu')">生成小程序码</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-container>
</template>

<script>
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { Download } from "./download";//文件下载
import {
  getTjQrcodeWxCode, getAreaData, getTjNames
} from "../../api/javaApi";
export default {
  components: {
    // saoma,
  },
  data() {
    return {
      showCode: false,
      proNameArr: [],
      cityNameArr: [],
      areaNameArr: [],
      download: new Download(),
      base64: '',
      src: '',  // 用于存储图片的Base64编码
      dayinanniu: true,
      uploadDisabled: false,
      communityNameArr: [],
      school_nameArr: [],
      check_name: '',
      canshu: {
        id: '',
        content_type: '',
        school_name: ""
      },
      xianshia: false,
      rules: {
        school_name: [
          { required: true, message: '请输入学校名称', trigger: 'blur' }
        ],
      }
    };
  },
  mounted() {
    this.fetchDataFromUrl();
  },
  created() {
  },
  methods: {
    fetchDataFromUrl() {
      const id = this.$route.query.id; // 替换 'key' 为你需要的参数名
      console.log(id);
      const content_type = this.$route.query.contentType;
      console.log(content_type);
      this.canshu.id = id;
      this.canshu.content_type = content_type;
    },
    async downLoad() {
      let downloadName = this.canshu.school_name + ".png";//文件名
      /*  let imgData = this.base64;//base64
      this.download.downloadFile(downloadName, imgData); */

      /* const imageUrl = this.src; // 图片的 base64 数据
      const blob = this.base64ToBlob(imageUrl);
      console.log(blob)
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = downloadName;
      link.click(); */

      const element = this.$refs.screenshotElement; // 确保你的元素有ref="screenshotElement"
      const canvas = await html2canvas(element);
      canvas.toBlob(blob => {
        saveAs(blob, downloadName);
      });
    },
    base64ToBlob(base64) {
      const byteString = atob(base64.split(',')[1]);
      const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async getCode() {
      let data = {
        id: this.canshu.id,
        school_name: this.canshu.school_name,
        content_type: this.canshu.content_type
      };
      getTjQrcodeWxCode(data)
        .then((res) => {
          if (res.data.code != 200) {
            this.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            this.base64 = res.data.data;
            this.src = 'data:image/jpeg;base64,' + res.data.data;
            this.showCode = true
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.xianshia = true
          this.getCode();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
/* 设置基础样式 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* 针对桌面和平板设备的样式 */
.container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  text-align: center;
  height: 100vh;
  font-size: 1vw;
}

/* 下面的CSS是针对移动端设备的样式 */
@media (max-width: 768px) {
  .container {
    max-width: 100%;
    /* 移动端的最大宽度设置为100% */
    padding: 5px;
    /* 减小了padding，以适应较小的屏幕 */
  }
}

img {
  max-width: 300px;
  margin-top: 10px;
}

.content {
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;

  .pics {
    position: absolute;
    top: 100px;
    right: 50px;
    width: 214px;
    height: auto;

  }

  .zitia {
    font-weight: 900;
  }

  .btn {
    margin-bottom: 30px;
  }

  .ma {
    width: 100%;
    height: 200px;
    margin: 20px 0;
    text-align: center;

    .qrcode {
      display: inline-block;
      width: 200px;
      height: 200px;
      box-sizing: border-box;

      img {
        width: 200px;
        height: 200px;
        background-color: #fff;
        background-position: center;
        box-sizing: border-box;
      }
    }
  }
}

.heisa {
  width: 100%;
  height: 100%;

  .dayinmaa {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
  }
}

.shangchuan {
  .avatar-uploader {
    /deep/ .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    /deep/ .el-upload:hover {
      border-color: #409EFF;
    }
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

/deep/ .disabled .el-upload--picture-card {
  display: none !important;
}
</style>
